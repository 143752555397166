import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
const queryString = require('query-string');

const WEB_DOMAIN =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://release.d1qnn21324dauf.amplifyapp.com/'
    : 'https://dev.d3emfstyapk7p3.amplifyapp.com/';
// 'http://localhost:3001/';

const API_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://uki2jdfvsc.execute-api.ap-northeast-2.amazonaws.com/Prod/'
    : 'https://56736f7def.execute-api.ap-northeast-2.amazonaws.com/Prod/';
// 'https://uki2jdfvsc.execute-api.ap-northeast-2.amazonaws.com/Prod/';

const AxiosInstance = axios.create({
  baseURL: API_URL,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json;charset=utf-8',
    'x-api-key':
      process.env.REACT_APP_ENV === 'production'
        ? 'MEjkGV1tMo8nAV7oNszrw8TfVLHqpCf83DUkFMVE'
        : 'Tx1TeV2qqv4xJnrh2DTnk8zjqPZh3urs5R43Fmpt',
    // 'x-api-key': 'Tx1TeV2qqv4xJnrh2DTnk8zjqPZh3urs5R43Fmpt',
  },
});
// x-apigw-api-id
const API_ID = process.env.REACT_APP_ENV === 'production' ? 'nt4v9ldhp0' : 'qms5n85660';

// const ObjectToParams = (obj) => {
//   var str = [];
//   for (var p in obj)
//     if (obj.hasOwnProperty(p)) {
//       str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
//     }
//   return '?' + str.join('&');
// };

const ObjectToParams = (object) => {
  return '?' + queryString.stringify(object);
};

export const ExamplePage = () => {
  const key = 'M2sRhUS6UppzGwGSGuwM';
  const unix = moment().valueOf().toString();
  console.log('key', key);
  console.log('unix', unix);
  const message = unix + unix;
  console.log('message', message);
  const secret = 'ov7yGL4gAwJGoSrXqKsQCazLQSsI8Unq';
  const hmacDigest = Base64.stringify(hmacSHA256(message, secret));
  console.log(hmacDigest);

  const [display, setDisplay] = useState({
    auth: '',
    rewardSync: '',
    rewardAsync: '',
    transferStatus: '',
    rewardPromise: '',
  });
  const [checkLink, setCheckLink] = useState(true);

  const reuqestAuth = () => {
    let memberId = document.getElementById('auth-input-memberId').value;
    let memberGroupId = document.getElementById('auth-input-memberGroupId').value;
    let token = document.getElementById('auth-input-token').value;
    let serviceNumber = document.getElementById('auth-input-serviceNumber').value;
    let serviceGroupId = document.getElementById('auth-input-serviceGroupId').value;
    let redirectUrl = encodeURI(document.getElementById('auth-input-redirectUrl').value);
    let serviceCallbackUrl = encodeURI(document.getElementById('auth-input-serviceCallbackUrl').value);
    let webView = document.getElementById('auth-input-webView').value;
    let failUrl = encodeURI(document.getElementById('auth-input-failUrl').value);
    let params = {
      memberId: memberId,
      memberGroupId: memberGroupId,
      token: token,
      serviceNumber: serviceNumber,
      serviceGroupId: serviceGroupId,
      redirectUrl: redirectUrl,
      serviceCallbackUrl: serviceCallbackUrl,
      webView,
      failUrl,
    };

    let requestUrl = WEB_DOMAIN + 'auth' + ObjectToParams(params);
    if (webView) {
      window.location.href = requestUrl;
    } else {
      let name = 'test';
      let options = 'top=10, left=10, width=480, height=600, status=no, menubar=no, toolbar=no, resizable=no';
      window.open(requestUrl, name, options);

      console.log('requestUrl', requestUrl);
    }
  };

  const requestRewardSync = () => {
    let memberId = document.getElementById('reward-sync-input-memberId').value;
    let memberGroupId = document.getElementById('reward-sync-input-memberGroupId').value;
    let serviceNumber = parseInt(document.getElementById('reward-sync-input-serviceNumber').value);
    let amount = document.getElementById('reward-sync-input-amount').value;
    let memo = document.getElementById('reward-sync-input-memo').value;
    let pass = document.getElementById('reward-sync-input-pass').value;

    let body = {
      memberId: memberId,
      memberGroupId: memberGroupId,
      serviceNumber: serviceNumber,
      amount: amount,
      memo: memo,
    };

    console.log('body', body);

    let requestUrl = 'reward/sync';
    if (pass) {
      requestUrl += '?pass=' + pass;
    }
    AxiosInstance.post(requestUrl, body).then(
      (res) => {
        console.log('res', res);
        if (res && res.data) {
          setDisplay({
            ...display,
            rewardSync: JSON.stringify(res.data),
          });
        }
      },
      (err) => {
        console.log('err', err);
        console.log('err.response', err.response);

        setDisplay({
          ...display,
          rewardSync: JSON.stringify(err.response.data),
        });
      },
    );
  };

  const requestRewardAsync = () => {
    let memberId = document.getElementById('reward-async-input-memberId').value;
    let memberGroupId = document.getElementById('reward-async-input-memberGroupId').value;
    let serviceNumber = parseInt(document.getElementById('reward-async-input-serviceNumber').value);
    let amount = document.getElementById('reward-async-input-amount').value;
    let memo = document.getElementById('reward-async-input-memo').value;
    let serviceCallbackUrl = encodeURI(document.getElementById('reward-async-input-serviceCallbackUrl').value);
    let pass = document.getElementById('reward-async-input-pass').value;

    let body = {
      memberId: memberId,
      memberGroupId: memberGroupId,
      serviceNumber: serviceNumber,
      amount: amount,
      memo: memo,
      serviceCallbackUrl: serviceCallbackUrl,
    };

    console.log('body', body);
    let requestUrl = 'reward/async';
    if (pass) {
      requestUrl += '?pass=' + pass;
    }
    AxiosInstance.post(requestUrl, body).then(
      (res) => {
        console.log('res', res);
        if (res && res.data) {
          setDisplay({
            ...display,
            rewardAsync: JSON.stringify(res.data),
          });
        }
      },
      (err) => {
        console.log('err', err.response);
        setDisplay({
          ...display,
          rewardAsync: JSON.stringify(err.response.data),
        });
      },
    );
  };

  const requestTransferStatus = () => {
    let transferSequence = document.getElementById('transferSequence-input-memberId').value;
    AxiosInstance.get('reward/status?transferSequence=' + transferSequence).then(
      (res) => {
        console.log('res', res);
        if (res && res.data) {
          setDisplay({
            ...display,
            transferStatus: JSON.stringify(res.data),
          });
        }
      },
      (err) => {
        setDisplay({
          ...display,
          transferStatus: JSON.stringify(err.response.data),
        });
      },
    );
  };

  const requestRewardPromise = () => {
    let memberId = document.getElementById('reward-promise-input-memberId').value;
    let memberGroupId = document.getElementById('reward-promise-input-memberGroupId').value;
    let serviceNumber = parseInt(document.getElementById('reward-promise-input-serviceNumber').value);
    let amount = document.getElementById('reward-promise-input-amount').value;
    let reserveTime = document.getElementById('reward-promise-input-reserveTime').value;
    let expireTime = document.getElementById('reward-promise-input-expireTime').value;
    let memo = document.getElementById('reward-promise-input-memo').value;
    let serviceCallbackUrl = encodeURI(document.getElementById('reward-promise-input-serviceCallbackUrl').value);
    let pass = document.getElementById('reward-promise-input-pass').value;

    let body = {
      memberId: memberId,
      memberGroupId: memberGroupId,
      serviceNumber: serviceNumber,
      amount: amount,
      reserveTime: moment(reserveTime).format('YYYY-MM-DD HH:mm:ss'),
      expireTime: expireTime ? moment(expireTime).format('YYYY-MM-DD HH:mm:ss') : null,
      checkLink: checkLink,
      memo: memo,
      serviceCallbackUrl: serviceCallbackUrl,
    };

    console.log('body', body);
    let requestUrl = 'reward/promise';
    if (pass) {
      requestUrl += '?pass=' + pass;
    }
    AxiosInstance.post(requestUrl, body).then(
      (res) => {
        console.log('res', res);
        if (res && res.data) {
          setDisplay({
            ...display,
            rewardPromise: JSON.stringify(res.data),
          });
        }
      },
      (err) => {
        console.log('err', err.response);
        setDisplay({
          ...display,
          rewardPromise: JSON.stringify(err.response.data),
        });
      },
    );
  };

  const requestWallet = () => {
    let memberId = document.getElementById('wallet-input-memberId').value;
    let memberGroupId = document.getElementById('wallet-input-memberGroupId').value;
    let token = document.getElementById('wallet-input-token').value;
    let webView = document.getElementById('auth-input-webView').value;
    let failUrl = encodeURIComponent(document.getElementById('auth-input-failUrl').value);
    let params = {
      memberId: memberId,
      memberGroupId: memberGroupId,
      token: token,
      webView: webView,
      failUrl: failUrl,
    };
    let requestUrl = WEB_DOMAIN + 'wallet' + ObjectToParams(params);

    let name = 'test';
    let options = 'top=10, left=10, width=480, height=600, status=no, menubar=no, toolbar=no, resizable=no';
    window.open(requestUrl, name, options);
    console.log('requestUrl', requestUrl);
  };

  const reuqestPayPrepare = () => {
    let amount = document.getElementById('pay-prepare-input-amount').value;
    let mallType = document.getElementById('pay-prepare-input-mallType').value;
    let mallOrderNo = document.getElementById('pay-prepare-input-mallOrderNo').value;
    let productName = document.getElementById('pay-prepare-input-productName').value;
    let orderDate = document.getElementById('pay-prepare-input-orderDate').value;
    let wonPrice = document.getElementById('pay-prepare-input-wonPrice').value;
    let closingPrice = document.getElementById('pay-prepare-input-closingPrice').value;
    let memberName = document.getElementById('pay-prepare-input-memberName').value;
    let etc = document.getElementById('pay-prepare-input-etc').value;

    let body = {
      amount: amount,
      mallType: mallType,
      mallOrderNo: mallOrderNo,
      productName: productName,
      orderDate: orderDate,
      wonPrice: wonPrice,
      closingPrice: closingPrice,
      memberName: memberName,
      etc: etc,
    };

    console.log('body', body);
    let requestUrl = 'pay/prepare';

    AxiosInstance.post(requestUrl, body).then(
      (res) => {
        console.log('res', res);
      },
      (err) => {
        console.log('err', err.response);
      },
    );
  };
  const reuqestPay = () => {
    let memberId = document.getElementById('pay-input-memberId').value;
    let memberGroupId = document.getElementById('pay-input-memberGroupId').value;
    let token = document.getElementById('pay-input-token').value;
    let serviceNumber = document.getElementById('pay-input-serviceNumber').value;
    let serviceCallbackUrl = document.getElementById('pay-input-serviceCallbackUrl').value;
    let webView = document.getElementById('pay-input-webView').value;
    let redirectUrl = document.getElementById('pay-input-redirectUrl').value;
    let failUrl = document.getElementById('pay-input-failUrl').value;
    let prepareKey = document.getElementById('pay-input-prepareKey').value;
    let memo = document.getElementById('pay-input-memo').value;

    let params = {
      memberId: memberId,
      memberGroupId: memberGroupId,
      token: token,
      serviceNumber: serviceNumber,
      serviceCallbackUrl: serviceCallbackUrl,
      webView: webView,
      failUrl: failUrl,
      redirectUrl: redirectUrl,
      prepareKey: prepareKey,
      memo: memo,
    };

    let requestUrl = WEB_DOMAIN + 'pay' + ObjectToParams(params);
    console.log('params', params);

    console.log('pay requestUrl', requestUrl);
    if (webView) {
      window.location.href = requestUrl;
    } else {
      let name = 'test';
      let options = 'top=10, left=10, width=480, height=600, status=no, menubar=no, toolbar=no, resizable=no';
      window.open(requestUrl, name, options);
      console.log('requestUrl', requestUrl);
    }
  };

  const requestTransferInfo = () => {
    let transferSequence = document.getElementById('transfer-info-input-transferSequence').value;

    AxiosInstance.get('transfer/info?transferSequence=' + transferSequence).then(
      (res) => {
        console.log('res', res);
      },
      (err) => {
        console.log('err', err.response);
      },
    );
  };

  const requestLinkList = () => {
    let startTime = document.getElementById('link-list-input-startTime').value;
    let endTime = document.getElementById('link-list-input-endTime').value;
    let serviceGroupId = document.getElementById('link-list-input-serviceGroupId').value;
    let type = document.getElementById('link-list-input-type').value;
    AxiosInstance.get(
      'link/list?startDate=' +
        startTime +
        '&endDate=' +
        endTime +
        '&serviceGroupId=' +
        serviceGroupId +
        '&type=' +
        type,
    ).then(
      (res) => {
        console.log('res', res);
      },
      (err) => {
        console.log('err', err);
      },
    );
  };

  const reuqestTest = () => {
    // let requestUrl = 'reward/async';

    // const retrunArray = [];
    // for (let i = 0; i < 20; i++) {
    //   let body = {
    //     memberId: 'test53',
    //     memberGroupId: 'hk',
    //     serviceNumber: 29,
    //     amount: 0.0001,
    //     memo: 'try Number :' + i,
    //   };
    //   (function () {
    //     AxiosInstance.post(requestUrl, body).then(
    //       (res) => {
    //         console.log('i :', i, 'res', res);
    //         retrunArray.push(res);
    //       },
    //       (err) => {
    //         console.log('i :', i, 'err', err.response);
    //       },
    //     );
    //   })();
    // }
    let linkListTimer = setInterval(() => {
      let params = {
        startDate: '2021-02-02T19:27',
        endDate: '2021-04-02T20:27',
      };
      let requestUrl = 'link/list' + ObjectToParams(params);

      let now = new Date();
      AxiosInstance.get(requestUrl).then(
        (res) => {
          console.log(now, ' link/list res', res);
        },
        (err) => {
          console.log(now, ' link/list err', err.response);
        },
      );
    }, 500);

    let AsyncTimer = setInterval(() => {
      let requestUrl = 'reward/async';

      let body = {
        memberId: 'test53',
        memberGroupId: 'hk',
        serviceNumber: 29,
        amount: 0.0000000001,
      };
      let now = new Date();

      AxiosInstance.post(requestUrl, body).then(
        (res) => {
          console.log(now, ' reward/async res', res);
        },
        (err) => {
          console.log(now, ' reward/async err', err.response);
        },
      );
    }, 3000);

    let SyncTimer = setInterval(() => {
      let requestUrl = 'reward/sync';

      let body = {
        memberId: 'test53',
        memberGroupId: 'hk',
        serviceNumber: 29,
        amount: 0.0000000001,
      };
      let now = new Date();

      AxiosInstance.post(requestUrl, body).then(
        (res) => {
          console.log(now, ' reward/sync res', res);
        },
        (err) => {
          console.log(now, ' reward/sync err', err.response);
        },
      );
    }, 5000);
  };

  const requestLinkExist = () => {
    let memberGroupId = document.getElementById('link-exist-input-memberGroupId').value;
    let memberId = document.getElementById('link-exist-input-memberId').value;

    AxiosInstance.get('link/exist?memberGroupId=' + memberGroupId + '&memberId=' + memberId).then(
      (res) => {
        console.log('res', res);
      },
      (err) => {
        console.log('err', err.response);
      },
    );
  };

  const requestKlaytnAccountInfo = () => {
    let accountId = document.getElementById('klaytn-acccount-input-accountId').value;

    AxiosInstance.get('hkAccount/info?accountId=' + accountId).then(
      (res) => {
        console.log('res', res);
      },
      (err) => {
        console.log('err', err.response);
      },
    );
  };

  const requestLinkCancel = () => {
    let memberId = document.getElementById('link-cancel-input-memberId').value;
    let memberGroupId = document.getElementById('link-cancel-input-memberGroupId').value;
    let body = {
      memberId: memberId,
      memberGroupId: memberGroupId,
    };
    AxiosInstance.post('link/cancel', body).then(
      (res) => {
        console.log('res', res);
      },
      (err) => {
        console.log('err', err.response);
      },
    );
  };

  const requestLinkRecovery = () => {
    let memberId = document.getElementById('link-recovery-input-memberId').value;
    let memberGroupId = document.getElementById('link-recovery-input-memberGroupId').value;
    let body = {
      memberId: memberId,
      memberGroupId: memberGroupId,
    };
    AxiosInstance.post('link/recovery', body).then(
      (res) => {
        console.log('res', res);
      },
      (err) => {
        console.log('err', err.response);
      },
    );
  };

  const requestKlaytnTicker = () => {
    AxiosInstance.get(`klaytn/ticker`).then(
      (res) => {
        console.log('res', res);
      },
      (err) => {
        console.log('err', err.response);
      },
    );
  };

  const requestUserInfo = () => {
    let memberId = document.getElementById('user-info-input-memberId').value;
    let memberGroupId = document.getElementById('user-info-input-memberGroupId').value;

    AxiosInstance.get(`user/info?memberId=${memberId}&memberGroupId=${memberGroupId}`).then(
      (res) => {
        console.log('res', res);
      },
      (err) => {
        console.log('err', err.response);
      },
    );
  };

  return (
    <article className="example-page">
      {/* <article className="example__container">
        <header>테스트</header>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={reuqestTest}>
            테스트
          </Button>
        </section>
      </article> */}
      <article className="example__container">
        <header>링크 연결</header>
        <section className="input__box">
          {/* default */}
          <TextField defaultValue="" id="auth-input-memberId" label="memberId" />
          <TextField defaultValue="" id="auth-input-token" label="token" />
          <TextField defaultValue="" id="auth-input-memberGroupId" label="memberGroupId" />
          <TextField id="auth-input-serviceGroupId" label="serviceGroupId" />

          {/* tv */}
          {/* <TextField defaultValue="" id="auth-input-memberId" label="memberId" />
          <TextField defaultValue="90171F52D3B54761AD45DD3AEA4D6208" id="auth-input-token" label="token" />
          <TextField defaultValue="tv" id="auth-input-memberGroupId" label="memberGroupId" /> */}

          {/* hk */}
          {/* <TextField defaultValue="khrock89@gmail.com" id="auth-input-memberId" label="memberId" />
          <TextField
            defaultValue="2067978e9fa285e9120c9fd60964d3a398b5499006e7abc0e7751b471ddcb8e270ccb2cd2789e443eb0706efbcdfc11ab9206230136bcb14b9eccf9e645148df8ac675d38b8c03207face3af463d8ad1f614abf8053bed0efb95507467ace69e01c56fe431ca97cf2f274671bc4463cab187a1be79756b39c3adaa503cdde04cddbc0af3f41a651c00fc9abadb6d3d23c903f87204c6002f57af5f34573d6471"
            id="auth-input-token"
            label="token"
          />
          <TextField defaultValue="hk" id="auth-input-memberGroupId" label="memberGroupId" /> */}

          {/* tv */}
          {/* <TextField
            defaultValue="https://master.d2j9ps53kuxa7u.amplifyapp.com/wallet?memberGroupId=tv&token=90171F52D3B54761AD45DD3AEA4D6208"
            id="auth-input-redirectUrl"
            label="redirectUrl"
          /> */}
          {/* hk */}
          {/* <TextField
            defaultValue="https://master.d2j9ps53kuxa7u.amplifyapp.com/wallet?memberId=khrock89@gmail.com&memberGroupId=hk&token=2067978e9fa285e9120c9fd60964d3a398b5499006e7abc0e7751b471ddcb8e270ccb2cd2789e443eb0706efbcdfc11ab9206230136bcb14b9eccf9e645148df8ac675d38b8c03207face3af463d8ad1f614abf8053bed0efb95507467ace69e01c56fe431ca97cf2f274671bc4463cab187a1be79756b39c3adaa503cdde04cddbc0af3f41a651c00fc9abadb6d3d23c903f87204c6002f57af5f34573d6471&webView=1&failUrl=https://naver.com"
            id="auth-input-redirectUrl"
            label="redirectUrl"
          /> */}
          {/* default */}
          <TextField defaultValue="" id="auth-input-redirectUrl" label="redirectUrl" />
          <TextField defaultValue="" id="auth-input-serviceCallbackUrl" label="serviceCallbackUrl" />
          <TextField defaultValue="" id="auth-input-webView" label="webView" />
          <TextField defaultValue="" id="auth-input-failUrl" label="failUrl" />
          <TextField id="auth-input-serviceNumber" label="serviceNumber" />
        </section>
        <section className="display__box"></section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={reuqestAuth}>
            링크 연결하기
          </Button>
        </section>
      </article>

      <article className="example__container">
        <header>Wallet</header>
        <section className="input__box">
          <TextField defaultValue="" id="wallet-input-memberId" label="memberId" />
          <TextField defaultValue="" id="wallet-input-memberGroupId" label="memberGroupId" />
          <TextField defaultValue="" id="wallet-input-token" label="token" />
          <TextField defaultValue="" id="wallet-input-webView" label="webView" />
          <TextField defaultValue="" id="wallet-input-failUrl" label="failUrl" />
        </section>
        <section className="display__box"></section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestWallet}>
            Wallet 페이지 보기
          </Button>
        </section>
      </article>

      <article className="example__container">
        <header>PAY prepare</header>
        <section className="input__box">
          <TextField defaultValue="0.0001" id="pay-prepare-input-amount" label="amount" />
          <TextField defaultValue="100200" id="pay-prepare-input-mallType" label="mallType" />
          <TextField defaultValue="20210602000001" id="pay-prepare-input-mallOrderNo" label="mallOrderNo" />
          <TextField
            defaultValue="리큅에어 서큘레이터 LAC-1904W 외 1건"
            id="pay-prepare-input-productName"
            label="productName"
          />
          <TextField defaultValue="2021-06-29 01:02:03" id="pay-prepare-input-orderDate" label="orderDate" />
          <TextField defaultValue="151000" id="pay-prepare-input-wonPrice" label="wonPrice" />
          <TextField defaultValue="1568" id="pay-prepare-input-closingPrice" label="closingPrice" />
          <TextField defaultValue="김희락" id="pay-prepare-input-memberName" label="memberName" />
          <TextField defaultValue="etc string" id="pay-prepare-input-etc" label="etc" />
        </section>
        <section className="display__box"></section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={reuqestPayPrepare}>
            PAY prepare
          </Button>
        </section>
      </article>

      <article className="example__container">
        <header>PAY</header>
        <section className="input__box">
          <TextField defaultValue="test70" id="pay-input-memberId" label="memberId" />
          <TextField defaultValue="hk" id="pay-input-memberGroupId" label="memberGroupId" />
          <TextField defaultValue="abcde" id="pay-input-token" label="token" />
          <TextField defaultValue="32" id="pay-input-serviceNumber" label="serviceNumber" />
          <TextField defaultValue="" id="pay-input-prepareKey" label="prepareKey" />
          <TextField
            defaultValue="https://naver.com?test=김희락"
            id="pay-input-serviceCallbackUrl"
            label="serviceCallbackUrl"
          />
          <TextField defaultValue="" id="pay-input-webView" label="webView" />
          <TextField defaultValue="https://naver.com?test=김희락" id="pay-input-redirectUrl" label="redirectUrl" />
          <TextField defaultValue="https://daum.net?test=김희락" id="pay-input-failUrl" label="failUrl" />
          <TextField defaultValue="memo" id="pay-input-memo" label="memo" />
        </section>
        <section className="display__box"></section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={reuqestPay}>
            PAY 페이지 보기
          </Button>
        </section>
      </article>

      <article className="example__container">
        <header>Transfer Info</header>
        <section className="input__box">
          <TextField id="transfer-info-input-transferSequence" label="transferSequence" />
        </section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestTransferInfo}>
            Transfer Info
          </Button>
        </section>
      </article>

      <article className="example__container">
        <header>Reward Sync</header>
        <section className="input__box">
          <TextField defaultValue="" id="reward-sync-input-memberId" label="memberId" />
          <TextField defaultValue="" id="reward-sync-input-memberGroupId" label="memberGroupId" />
          <TextField defaultValue="" id="reward-sync-input-serviceNumber" label="serviceNumber" />
          <TextField defaultValue="0.0001" id="reward-sync-input-amount" label="amount" disabled={true} />
          <TextField id="reward-sync-input-memo" label="memo" />
          <TextField defaultValue="" id="reward-sync-input-pass" label="pass" />
        </section>
        <section className="display__box">
          <span>{display.rewardSync}</span>
        </section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestRewardSync}>
            Reward Sync
          </Button>
        </section>
      </article>

      <article className="example__container">
        <header>Reward Async</header>
        <section className="input__box">
          <TextField defaultValue="" id="reward-async-input-memberId" label="memberId" />
          <TextField defaultValue="" id="reward-async-input-memberGroupId" label="memberGroupId" />
          <TextField defaultValue="" id="reward-async-input-serviceNumber" label="serviceNumber" />
          <TextField defaultValue="0.0001" id="reward-async-input-amount" label="amount" disabled={true} />
          <TextField id="reward-async-input-memo" label="memo" />
          <TextField id="reward-async-input-serviceCallbackUrl" label="serviceCallbackUrl" />
          <TextField defaultValue="" id="reward-async-input-pass" label="pass" />
        </section>
        <section className="display__box">
          <span>{display.rewardAsync}</span>
        </section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestRewardAsync}>
            Reward Async
          </Button>
        </section>
      </article>

      <article className="example__container">
        <header>Transfer Status</header>
        <section className="input__box">
          <TextField id="transferSequence-input-memberId" label="transferSequence" />
        </section>
        <section className="display__box">
          <span>{display.transferStatus}</span>
        </section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestTransferStatus}>
            Transfer Status
          </Button>
        </section>
      </article>

      <article className="example__container">
        <header>Reward Promise</header>
        <section className="input__box">
          <TextField defaultValue="" id="reward-promise-input-memberId" label="memberId" />
          <TextField defaultValue="" id="reward-promise-input-memberGroupId" label="memberGroupId" />
          <TextField defaultValue="" id="reward-promise-input-serviceNumber" label="serviceNumber" />
          <TextField defaultValue="0.0001" id="reward-promise-input-amount" label="amount" disabled={true} />
          <TextField id="reward-promise-input-memo" label="memo" />
          <TextField id="reward-promise-input-serviceCallbackUrl" label="serviceCallbackUrl" />

          <TextField
            defaultValue={moment(new Date(new Date().getTime() + 24 * 60 * 60000)).format('YYYY-MM-DDTHH:mm')}
            label="reserveTime"
            id="reward-promise-input-reserveTime"
            type="datetime-local"
          />
          <TextField
            label="expireTime"
            defaultValue={moment(new Date(new Date().getTime() + 25 * 60 * 60000)).format('YYYY-MM-DDTHH:mm')}
            id="reward-promise-input-expireTime"
            type="datetime-local"
          />
          <FormControl>
            <InputLabel id="label-reward-promise-input-checkLink">checkLink</InputLabel>
            <Select
              labelId="label-reward-promise-input-checkLink"
              id="reward-promise-input-checkLink"
              value={checkLink}
              onChange={(e) => {
                setCheckLink(e.target.value);
              }}
              label="checkLink"
            >
              <MenuItem value={true}>TRUE</MenuItem>
              <MenuItem value={false}>FALSE</MenuItem>
            </Select>
          </FormControl>
          <TextField defaultValue="" id="reward-promise-input-pass" label="pass" />
        </section>
        <section className="display__box">
          <span>{display.rewardPromise}</span>
        </section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestRewardPromise}>
            Reward Promise
          </Button>
        </section>
      </article>

      <article className="example__container">
        <header>LINK LIST</header>
        <section className="input__box">
          <TextField
            defaultValue={moment(new Date(new Date().getTime() + 24 * 60 * 60000)).format('YYYY-MM-DDTHH:mm')}
            label="reserveTime"
            id="link-list-input-startTime"
            type="datetime-local"
          />
          <TextField
            label="expireTime"
            defaultValue={moment(new Date(new Date().getTime() + 25 * 60 * 60000)).format('YYYY-MM-DDTHH:mm')}
            id="link-list-input-endTime"
            type="datetime-local"
          />
          <TextField defaultValue="" id="link-list-input-serviceGroupId" label="service Group Id" />
          <TextField defaultValue="" id="link-list-input-type" label="type(count)" />
        </section>
        <section className="display__box"></section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestLinkList}>
            LINK LIST
          </Button>
        </section>
      </article>

      <article className="example__container">
        <header>LINK EXIST</header>
        <section className="input__box">
          <TextField defaultValue="" id="link-exist-input-memberGroupId" label="memberGroupIdd" />
          <TextField defaultValue="" id="link-exist-input-memberId" label="memberId" />
        </section>
        <section className="display__box"></section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestLinkExist}>
            LINK EXIST
          </Button>
        </section>
      </article>

      <article className="example__container">
        <header>Klaytn Account Info</header>
        <section className="input__box">
          <TextField defaultValue="" id="klaytn-acccount-input-accountId" label="accountId" />
        </section>
        <section className="display__box"></section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestKlaytnAccountInfo}>
            Klaytn Account Info{' '}
          </Button>
        </section>
      </article>
      <article className="example__container">
        <header>Link Cancel</header>
        <section className="input__box">
          <TextField defaultValue="" id="link-cancel-input-memberId" label="memberId" />
          <TextField defaultValue="" id="link-cancel-input-memberGroupId" label="memberGroupId" />
        </section>
        <section className="display__box"></section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestLinkCancel}>
            Link Cancel
          </Button>
        </section>
      </article>
      <article className="example__container">
        <header>Link Recovery</header>
        <section className="input__box">
          <TextField defaultValue="" id="link-recovery-input-memberId" label="memberId" />
          <TextField defaultValue="" id="link-recovery-input-memberGroupId" label="memberGroupId" />
        </section>
        <section className="display__box"></section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestLinkRecovery}>
            Link Recovery
          </Button>
        </section>
      </article>
      <article className="example__container">
        <header>Link Recovery</header>
        <section className="input__box">
          <TextField defaultValue="" id="link-recovery-input-memberId" label="memberId" />
          <TextField defaultValue="" id="link-recovery-input-memberGroupId" label="memberGroupId" />
        </section>
        <section className="display__box"></section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestLinkRecovery}>
            Link Recovery
          </Button>
        </section>
      </article>
      <article className="example__container">
        <header>Klaytn Ticker</header>

        <section className="display__box"></section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestKlaytnTicker}>
            Klaytn Ticker
          </Button>
        </section>
      </article>

      <article className="example__container">
        <header>User Info</header>
        <section className="input__box">
          <TextField defaultValue="" id="user-info-input-memberId" label="memberId" />
          <TextField defaultValue="" id="user-info-input-memberGroupId" label="memberGroupId" />
        </section>
        <section className="display__box"></section>
        <section className="button__box">
          <Button variant="contained" color="primary" onClick={requestUserInfo}>
            User Info
          </Button>
        </section>
      </article>

      <div style={{ minHeight: 40 }}></div>
    </article>
  );
};
