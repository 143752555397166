import React, { useEffect } from 'react';
import Utils from '../utils';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

// x-apigw-api-id
const API_ID = process.env.REACT_APP_ENV === 'production' ? 'nt4v9ldhp0' : 'qms5n85660';

export const Home = () => {
  const API_LIST = Utils.getApiList();
  console.log('API_LIST', API_LIST);
  return (
    <div className="home-page">
      {API_LIST.map((api, index) => {
        let documentClassName = 'api-document';

        if (api.status) {
          documentClassName += ' on';
        }
        return (
          <article className={documentClassName} id={'api-document-' + api.url}>
            <section className="resource">
              <b className={api.method}>{api.method}</b>
              <span>{api.url}</span>
            </section>

            <section className="desc">
              <header>
                <h1>요약</h1>
              </header>
              <span>{api.summary}</span>
            </section>
            <section className="comments">
              <header>
                <h1>설명</h1>
              </header>
              <ul>
                {api.comments.map((comment, comment_index) => {
                  return (
                    <li>
                      <span>{comment}</span>
                    </li>
                  );
                })}
              </ul>
            </section>
            {api.path === 'auth' && (
              <section className="diagram">
                <header>
                  <h1>Flow Chart</h1>
                </header>
                <ul>
                  <li>
                    <div>Request(mobile)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Shift Web(popup)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>User Action(Kakao - auth)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>User Action(Confirm)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Request GET serviceCallbackUrl</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Close popup or Redirect window.opener</div>
                  </li>
                  <li>
                    <div>Request(webview)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Shift Web(window.location.href)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>User Action(Kakao - auth)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>User Action(Confirm)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Request GET serviceCallbackUrl</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Redirect window.location.href</div>
                  </li>
                  <li>
                    <div>Request(PC WEB)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Shift Web(popup)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>User Action(QR CODE - Kakao)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>User Action(Confirm)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Request GET serviceCallbackUrl</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Close popup or Redirect window.opener</div>
                  </li>
                </ul>
              </section>
            )}
            {api.path === 'pay' && (
              <section className="diagram">
                <header>
                  <h1>Flow Chart</h1>
                </header>
                <ul>
                  <li>
                    <div>Request(mobile)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Shift Web(popup)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>User Action(Kakao - auth)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>User Action(Confirm)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Request GET serviceCallbackUrl</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Close popup or Redirect window.opener</div>
                  </li>
                  <li>
                    <div>Request(webview)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Shift Web(window.location.href)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>User Action(Kakao - auth)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>User Action(Confirm)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Request GET serviceCallbackUrl</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Redirect window.location.href</div>
                  </li>
                  <li>
                    <div>Request(PC WEB)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Shift Web(popup)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>User Action(QR CODE - Kakao)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>User Action(Confirm)</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Request GET serviceCallbackUrl</div>
                    <ArrowForwardIcon></ArrowForwardIcon>
                    <div>Close popup or Redirect window.opener</div>
                  </li>
                </ul>
              </section>
            )}
            {api.method !== 'URL' && (
              <section className="response">
                <header>
                  <h1>headers</h1>
                </header>
                <div className="table__box">
                  <ul className="header">
                    <li>
                      <span>Name</span>
                    </li>
                    <li style={{ maxWidth: 160 }}>
                      <span>Type</span>
                    </li>
                    <li className="desc">
                      <span>Description</span>
                    </li>
                  </ul>
                  <ul>
                    <li className="name">
                      <span>x-api-key</span>
                      <b style={{ marginLeft: 3 }} className="required">
                        (required)
                      </b>
                    </li>
                    <li style={{ maxWidth: 160 }}>
                      <span>string</span>
                    </li>
                    <li className="desc">
                      <span>AWS API KEY</span>
                    </li>
                  </ul>
                  <ul>
                    <li className="name">
                      <span>x-apigw-api-id</span>
                      <b style={{ marginLeft: 3 }} className="required">
                        (required)
                      </b>
                    </li>
                    <li style={{ maxWidth: 160 }}>
                      <span>string</span>
                    </li>
                    <li className="desc">
                      <span>{API_ID}</span>
                    </li>
                  </ul>
                </div>
              </section>
            )}
            {api.params && (
              <section className="parameters">
                <header>
                  <h1>parameters</h1>
                </header>
                <div className="table__box">
                  <ul className="header">
                    <li>
                      <span>Name</span>
                    </li>
                    <li style={{ maxWidth: 160 }}>
                      <span>Type</span>
                    </li>
                    <li className="desc">
                      <span>Description</span>
                    </li>
                  </ul>
                  {api.params.map((para, para_index) => {
                    return (
                      <ul>
                        <li className="name">
                          <span>
                            {para.value} {para.required && <b className="required">(required)</b>}
                          </span>
                        </li>
                        <li style={{ maxWidth: 160 }}>
                          <span>{para.type}</span>
                        </li>
                        <li className="desc">
                          <span>{Utils.getParamDesc(para.value)}</span>
                        </li>
                      </ul>
                    );
                  })}
                </div>
              </section>
            )}
            {api.body && (
              <section className="parameters">
                <header>
                  <h1>Body</h1>
                </header>
                <div className="table__box">
                  <ul className="header">
                    <li>
                      <span>Name</span>
                    </li>
                    <li style={{ maxWidth: 160 }}>
                      <span>Type</span>
                    </li>
                    <li className="desc">
                      <span>Description</span>
                    </li>
                  </ul>
                  {api.body.map((item, para_index) => {
                    return (
                      <ul>
                        <li className="name">
                          <span>
                            {item.value} {item.required && <b className="required">(required)</b>}
                          </span>
                        </li>
                        <li style={{ maxWidth: 160 }}>
                          <span>{item.type}</span>
                        </li>
                        <li className="desc">
                          <span>{Utils.getParamDesc(item.value)}</span>
                        </li>
                      </ul>
                    );
                  })}
                </div>
              </section>
            )}

            {api.response && (
              <section className="response">
                <header>
                  <h1>Response</h1>
                </header>
                <div className="table__box">
                  <ul className="header">
                    <li>
                      <span>Name</span>
                    </li>
                    <li style={{ maxWidth: 160 }}>
                      <span>Type</span>
                    </li>
                    <li className="desc">
                      <span>Description</span>
                    </li>
                  </ul>
                  {api.response.map((res, para_index) => {
                    return (
                      <ul>
                        <li className="name">
                          <span>{res.value}</span>
                        </li>
                        <li style={{ maxWidth: 160 }}>
                          <span>{res.type}</span>
                        </li>
                        <li className="desc">
                          <span>{Utils.getResDesc(res.value)}</span>
                        </li>
                      </ul>
                    );
                  })}
                </div>
              </section>
            )}
            {api.code && (
              <section>
                <header>
                  <h1>ERROR CODE</h1>
                </header>
                <ul className="code-list">
                  {api.code.map((code, index) => {
                    return (
                      <li>
                        <span className="code">{code}</span>
                        <div className="message">
                          <span>{Utils.getCodeMessage(code)}</span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </section>
            )}
          </article>
        );
      })}
    </div>
  );
};
