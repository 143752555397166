import React, { useState, useEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import { Navigation } from '../components';

export const MobileLayout = (props) => {
  let { Component, headerText, ...rest } = props;

  return (
    <div className="mobile-layout" id="mobile-layout">
      <div className="mobile-header">
        <div className="mobile-header-wrapper">
          <div className="logo">
            <Link to="/example">Shift API Example</Link>
          </div>
        </div>
      </div>
      <div className="content-wrapper">
        <div className="content__container" id="content__container">
          <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
        </div>
      </div>
    </div>
  );
};
