const WEB_DOMAIN =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://user-shift.hankyung.com/'
    : 'https://dev.d3emfstyapk7p3.amplifyapp.com/';
const API_URL =
  process.env.REACT_APP_ENV === 'production'
    ? 'backend-shift-api.hankyung.com/Prod/'
    : 'https://56736f7def.execute-api.ap-northeast-2.amazonaws.com/Prod/';

export const RESULT_LIST = [
  // 명확하고 중요한 이슈 : 1000번대 (서비스를 개발하면서 자주 마주칠 것 들)
  // 덜 중요한 이슈들 : 관심없는 것들 2000번대
  //
  //SHIFT
  {
    id: 1001,
    message: 'Link가 연결되지 않음.',
  },
  {
    id: 1002,
    message: '이미 Link된 유저입니다.',
  },
  {
    id: 1011,
    message: '해당 서비스의 한경 클레이튼 정보가 없습니다.',
  },
  {
    id: 1012,
    message: '해당 유저의 HK Server 로그인 정보가 없습니다.',
  },
  {
    id: 1013,
    message: '해당 멤버 그룹과 Link 기록이 있는 유저입니다.',
  },
  {
    id: 1014,
    message: 'Transfer 기록이 없습니다.',
  },
  {
    id: 1015,
    message: '올바르지 않은 memberGroupId입니다.',
  },
  {
    id: 1016,
    message: '서비스의 memberGroupId와 입력받은 memberGroupId가 일치하지 않습니다.',
  },
  {
    id: 1017,
    message: '요청 받은 서비스가 존재하지 않습니다.',
  },
  {
    id: 1018,
    message: '요청 받은 서비스 그룹이 존재하지 않습니다.',
  },
  {
    id: 1019,
    message: 'Link 해지 대기에 요청 받은 정보가 없습니다.',
  },
  {
    id: 1021,
    message: 'KAS 클레이 전송 실패',
  },
  {
    id: 1022,
    message: 'KAS 클레이 전송 트랜잭션 체크 에러',
  },
  {
    id: 1023,
    message: 'KAS 잔액 조회 에러',
  },
  {
    id: 1031,
    message: '[SHIFT] Link Crreate Duplicate',
  },
  {
    id: 1041,
    message: '[HK 통합] 로그인 이슈',
  },

  //5000번대 transfer 이슈
  {
    id: 1051,
    message: 'transfer transaction 결과를 알 수 없습니다.',
  },
  {
    id: 1052,
    message: 'trnasfer transaction CommitError',
  },
  {
    id: 1053,
    message: 'Inalid Pay PrepareKey',
  },
  {
    id: 1054,
    message: 'Expired Pay PrepareKey',
  },
  {
    id: 1055,
    message: 'Not Ready Status Pay PrepareKey',
  },
  //2000번대
  {
    id: 2001,
    message: 'KLIP API ERROR',
  },
  {
    id: 2002,
    message: 'KAS API ERROR',
  },
  {
    id: 2011,
    message: 'SQL ERROR',
  },
  {
    id: 2021,
    message: 'AWS ERROR',
  },

  //HTTP
  {
    id: 3000,
    message: '요청 파라미터 확인',
  },
  {
    id: 4000,
    meessage: 'token expire',
  },
  {
    id: 5000,
    meessage: 'cognito error',
  },
  {
    id: 6000,
    message: 'poll time out',
  },
  //LAMBDA ERROR
  {
    id: 10101,
    message: 'transfer row dont exist txHash',
  },
  {
    id: 10201,
    message: 'SQL ERROR [SQL message]',
  },
  {
    id: 10301,
    message: 'LAMBDA ERROR',
  },
];

export const PARAMETERS_DESCRIPTION = [
  {
    value: 'startDate',
    text: '2020-01-01 00:00:00',
  },
  {
    value: 'endDate',
    text: '2020-01-01 00:00:00',
  },
  {
    value: 'orderDate',
    text: '2020-01-01 00:00:00',
  },
  {
    value: 'memberId',
    text: '',
  },
  {
    value: 'memberGroupId',
    text: 'hk, tv',
  },
  {
    value: 'token',
    text: '',
  },
  {
    value: 'amount',
    text: 'KLAY',
  },
  {
    value: 'redirectUrl',
    text: 'status 성공시에 호출되는 redirectUrl (encodeURIComponent)',
  },
  {
    value: 'serviceCallbackUrl',
    text: 'lambda에서 HK Server를 호출 - GET (encodeURIComponent),PAY의 경우 POST로 호출',
  },
  {
    value: 'transferSequence',
    text: 'transferSequence',
  },
  {
    value: 'checkLink',
    text: 'True면 링크된 유저만 예약, False면 링크안된 유저도 예약',
  },
  {
    value: 'webView',
    text:
      '어떤 값(ex. 1 or true)이든 있으면 window.location.href로 url 변경 로직 수행(디폴트 로직의 경우, window.opener.location.href를 사용)',
  },
  {
    value: 'failUrl',
    text: 'webView의 경우 팝업 window.close()를 failUrl로 대신 수행함 (encodeURIComponent)',
  },
  {
    value: 'type',
    text: 'count 입력시 예제 response) count : 100',
  },
  {
    value: 'accountId',
    text: 'Klaytn 계정 아이디 COM, PAPER, TV',
  },
];

export const RESPONSE_DESCRIPTION = [
  {
    value: 'rewardSequece',
    text: 'rewardSequece',
  },
  {
    value: 'transferSequence',
    text: 'transferSequence',
  },
  {
    value: 'status',
    text: 'status',
  },
  {
    value: 'result',
    text: '결과',
  },
  {
    value: 'transferStatus',
    text: 'submit,pending,success,fail',
  },
];

export const API_LIST = [
  //auth
  {
    status: true,
    method: 'URL',
    url: WEB_DOMAIN + 'auth',
    summary: 'HK 멤버 ID와 그룹 ID를 Klip 서비스에 연동하기 위한 팝업페이지 호출',
    comments: [
      '통합회원은 memberId 필수, TV회원은 memberId생략',
      '웹뷰 파라미터를 사용 할 경우, redirectUrl, failUrl을 설정해 주어야 합니다.',
      'redirectUrl의 search 파라미터 값으로 memberId, isNew 값을 반환합니다. (isNew값은 1 or 0이며, 1이 신규생성을 의미합니다.)',
    ],
    auth: false,
    path: 'auth',
    params: [
      {
        value: 'memberId',
        required: false,
        type: 'String',
      },
      {
        value: 'memberGroupId',
        required: true,
        type: 'String',
      },
      {
        value: 'token',
        required: true,
        type: 'String',
      },
      {
        value: 'serviceGroupId',
        required: true,
        type: 'String',
      },
      {
        value: 'serviceNumber',
        required: false,
        type: 'Number',
      },
      {
        value: 'serviceCallbackUrl',
        required: false,
        type: 'String',
      },
      {
        value: 'redirectUrl',
        required: false,
        type: 'String',
      },
      {
        value: 'failUrl',
        required: false,
        type: 'string',
      },
      {
        value: 'webView',
        required: false,
        type: 'String',
      },
    ],
    body: null,
    response: null,
  },
  //user
  {
    status: true,
    method: 'GET',
    url: API_URL + 'user/info',
    path: 'user/info',
    summary: '유저의 링크, 임시 링크, 전송 기록 조회',
    comments: ['linktemp는 reg_dt 최신순', 'transfer는 transfer_reg_dt 최신순'],
    params: [
      {
        value: 'memberId',
        required: true,
        type: 'String',
      },
      {
        value: 'memberGroupId',
        required: true,
        type: 'String',
      },
    ],
    body: null,
    response: [
      {
        value: 'result',
        type: 'Boolean',
      },
      {
        value: 'link',
        type: 'Object',
      },
      {
        value: 'linkTemp',
        type: 'Array',
      },
      {
        value: 'transfer',
        type: 'Array',
      },
    ],
    code: ['3000', '2011'],
  },
  //link
  {
    status: true,
    method: 'GET',
    url: API_URL + 'link/list',
    path: 'link/list',
    summary: '유저 정보 리스트 조회',
    comments: ['serviceGroupId 입력하지 않을 경우에는 모든 서비스그룹 아이디를 조회한 결과값을 반환'],
    params: [
      {
        value: 'startDate',
        required: true,
        type: 'Date',
      },
      {
        value: 'endDate',
        required: true,
        type: 'Date',
      },
      {
        value: 'serviceGroupId',
        type: 'String',
      },
      {
        value: 'type',
        required: false,
        type: 'String',
      },
    ],
    body: null,
    response: [
      {
        value: 'list',
        type: 'Array',
      },
    ],
    code: ['3000', '2011'],
  },
  {
    status: true,
    method: 'GET',
    url: API_URL + 'link/exist',
    path: 'link/exist',
    summary: 'HK 멤버 ID와 그룹 ID의 Link 정보',
    comments: ['Link 여부에 따른 UI/UX에 사용', 'info의 klip_new가 1이면 신규 클립 유저, 0이면 기존 클립 유저'],
    params: [
      {
        value: 'memberId',
        required: true,
        type: 'String',
      },
      {
        value: 'memberGroupId',
        required: true,
        type: 'String',
      },
    ],
    body: null,
    response: [
      {
        value: 'result',
        type: 'Boolean',
      },
      {
        value: 'info',
        type: 'Object',
      },
    ],
    code: ['3000', '2011'],
  },
  {
    status: true,
    method: 'POST',
    url: API_URL + 'link/cancel',
    path: 'link/cancel',
    summary: '링크 해지',
    comments: ['한경 유저 탈퇴 시 링크 해지'],
    params: [
      {
        value: 'memberId',
        required: true,
        type: 'String',
      },
      {
        value: 'memberGroupId',
        required: true,
        type: 'String',
      },
    ],
    body: null,
    response: [
      {
        value: 'result',
        type: 'Boolean',
      },
    ],
    code: ['3000', '1001', '2011'],
  },
  //wallet
  {
    status: true,
    method: 'URL',
    url: WEB_DOMAIN + 'wallet',
    path: 'wallet',
    summary: '유저의 Wallet 팝업페이지 호출',
    comments: ['통합회원은 memberId 필수, TV회원은 memberId생략'],
    params: [
      {
        value: 'memberId',
        required: false,
        type: 'String',
      },
      {
        value: 'token',
        required: true,
        type: 'String',
      },
      {
        value: 'memberGroupId',
        required: true,
        type: 'String',
      },
      {
        value: 'serviceGroupId',
        required: true,
        type: 'String',
      },
      {
        value: 'webView',
        required: false,
        type: 'String',
      },
      {
        value: 'failUrl',
        required: false,
        type: 'string',
      },
    ],
    body: null,
    response: null,
  },
  //reward
  {
    status: true,
    method: 'POST',
    url: API_URL + 'reward/sync',
    path: 'reward/sync',
    summary: '리워드 지급(sync) - 트랜잭션 컴플리트 보장',
    comments: [],
    params: null,
    body: [
      {
        value: 'serviceNumber',
        required: true,
        type: 'Number',
      },
      {
        value: 'amount',
        required: true,
        type: 'Number',
      },
      {
        value: 'memberId',
        required: true,
        type: 'String',
      },
      {
        value: 'memberGroupId',
        required: true,
        type: 'String',
      },
      {
        value: 'memo',
        required: false,
        type: 'string',
      },
    ],
    response: [
      {
        value: 'transactionHash',
        type: 'string',
      },
      {
        value: 'transferSequence',
        type: 'Number',
      },
      {
        value: 'status',
        type: 'string',
      },
    ],
    code: ['3000', '2011', '1001', '1011', '1016', '1023', '1051', '1052', '2002'],
  },
  {
    status: true,
    method: 'POST',
    url: API_URL + 'reward/async',
    path: 'reward/async',
    summary: '리워드 지급(async) - reward status를 요청하여 결과 확인',
    comments: ['HK 서비스마다 HK Server의 Callback-API(result) 전달(optional)'],
    params: null,
    body: [
      {
        value: 'serviceNumber',
        required: true,
        type: 'Number',
      },
      {
        value: 'amount',
        required: true,
        type: 'Number',
      },
      {
        value: 'memberId',
        required: true,
        type: 'String',
      },
      {
        value: 'memberGroupId',
        required: true,
        type: 'String',
      },
      {
        value: 'memo',
        required: false,
        type: 'string',
      },
      {
        value: 'serviceCallbackUrl',
        required: false,
        type: 'string',
      },
    ],
    response: [
      {
        value: 'transactionHash',
        type: 'string',
      },
      {
        value: 'transferSequence',
        type: 'Number',
      },
      {
        value: 'status',
        type: 'string',
      },
    ],
    code: ['3000', '2011', '1001', '1011', '1016', '1023', '2002'],
  },
  {
    status: true,
    method: 'POST',
    url: API_URL + 'reward/promise',
    path: 'reward/promise',
    summary: '리워드 지급(promise) - 예약 지급',
    comments: [
      'checkLink가 true이면 link된 유저에게만 리워드 지급하며 link가 안된 유저의 트랜잭션은 삭제',
      'checkLink가 false이면 Link가 연결 되었을 때 지급 함',
      'expireTime이 null일 경우 정책에 따라 1년 또는 무제한의 기본값을 가짐',
    ],
    params: null,
    body: [
      {
        value: 'serviceNumber',
        required: true,
        type: 'Number',
      },
      {
        value: 'amount',
        required: true,
        type: 'Number',
      },
      {
        value: 'memberId',
        required: true,
        type: 'String',
      },
      {
        value: 'memberGroupId',
        required: true,
        type: 'String',
      },
      {
        value: 'reserveTime',
        required: true,
        type: 'DateTime',
      },
      {
        value: 'expireTime',
        required: false,
        type: 'DateTime',
      },
      {
        value: 'memo',
        required: false,
        type: 'string',
      },
      {
        value: 'serviceCallbackUrl',
        required: false,
        type: 'string',
      },
      {
        value: 'checkLink',
        required: false,
        type: 'Boolean',
      },
    ],
    response: [
      {
        value: 'rewardSequece',
        type: 'Number',
      },
    ],
    code: ['3000', '2011', '1001', '1011'],
  },
  {
    status: true,
    method: 'GET',
    url: API_URL + 'reward/status',
    path: 'reward/status',
    summary: 'reward sequence의 상태값 확인',
    comments: [],
    params: [
      {
        value: 'transferSequence',
        required: true,
        type: 'String',
      },
    ],
    body: null,
    response: [
      {
        value: 'transactionStatus',
        type: 'string',
      },
    ],
    code: ['3000', '2011', '1014'],
  },
  //pay
  {
    status: true,
    method: 'POST',
    url: API_URL + 'pay/prepare',
    path: 'pay/prepare',
    summary: '결제 요청키 발급',
    comments: ['발급된 payPrepareKey는 pay popup page호출시에 사용합니다.', 'prepareKey의 유효기간은 10분입니다.'],
    params: [
      {
        value: 'amount',
        required: true,
        type: 'String',
      },
      {
        value: 'mallType',
        required: true,
        type: 'String',
      },
      {
        value: 'mallOrderNo',
        required: true,
        type: 'String',
      },
      {
        value: 'productName',
        required: true,
        type: 'String',
      },
      {
        value: 'orderDate',
        required: true,
        type: 'String',
      },
      {
        value: 'wonPrice',
        required: true,
        type: 'Number',
      },
      {
        value: 'closingPrice',
        required: true,
        type: 'Number',
      },
      {
        value: 'memberName',
        required: true,
        type: 'String',
      },
      {
        value: 'etc',
        required: false,
        type: 'String',
      },
    ],
    body: null,
    response: [
      {
        value: 'result',
        type: 'Boolean',
      },
      {
        value: 'prepareKey',
        type: 'String',
      },
    ],
    code: ['3000', '2011'],
  },
  {
    status: true,
    method: 'URL',
    url: WEB_DOMAIN + 'pay',
    path: 'pay',
    summary: 'HK 멤버 Klaytn Pay 서비스를 위한 팝업 호출',
    comments: [
      '통합회원은 memberId 필수, TV회원은 memberId생략',
      '웹뷰 파라미터를 사용 할 경우, redirectUrl, failUrl을 설정해 주어야 합니다.',
      'redirectUrl, failUrl은 search 파라미터 값으로 pay/prepare에서 입력받은 추가 파라미터값을 반환합니다.',
      '만약 유효하지 않은(존재하지 않은) prepare key가 들어왔을 경우, failUrl을 호출하게 되는데 이때는 failUrl에 추가 파라미터 값을 돌려줄 수 없습니다.',
      '유저가 결제는 성공했지만, 서비스로 돌아와 결제확인을 누르지 않거나, 이탈하였을 경우를 대비하여, serviceCallbackUrl을 셋팅하여 데이터를 동기화하는 것을 권장합니다.',
      'serviceCallbackUrl은 결제 성공시에 POST Method로 호출되며, transferSequence값이 바디 값으로 입력됩니다.',
    ],
    params: [
      {
        value: 'memberId',
        required: true,
        type: 'String',
      },
      {
        value: 'memberGroupId',
        required: true,
        type: 'String',
      },
      {
        value: 'token',
        required: true,
        type: 'String',
      },
      {
        value: 'serivceNumber',
        required: true,
        type: 'Number',
      },
      {
        value: 'prepareKey',
        required: true,
        type: 'String',
      },
      {
        value: 'serviceGroupId',
        required: true,
        type: 'String',
      },
      {
        value: 'serviceCallbackUrl',
        required: false,
        type: 'String',
      },
      {
        value: 'webView',
        required: false,
        type: 'String',
      },
      {
        value: 'redirectUrl',
        required: false,
        type: 'String',
      },
      {
        value: 'failUrl',
        required: false,
        type: 'String',
      },
      {
        value: 'memo',
        required: false,
        type: 'String',
      },
    ],
    body: null,
    response: null,
    result: null,
    code: ['3000', '1011', '1023', '1016', '1001', '1053', '1054', '1055', '2001'],
  },
  {
    status: true,
    method: 'GET',
    url: API_URL + 'transfer/info',
    path: 'transfer/info',
    summary: 'transfer의 정보값',
    comments: ['transferSequence로 결제 정보 조회'],
    params: [
      {
        value: 'transferSequence',
        required: true,
        type: 'String',
      },
    ],
    body: null,
    response: [
      {
        value: 'transactionSequence',
        type: 'Number',
      },
      {
        value: 'transactionStatus',
        type: 'String',
      },
      {
        value: 'serviceName',
        type: 'String',
      },
      {
        value: 'serviceDescription',
        type: 'String',
      },
      {
        value: 'linkNumber',
        type: 'Number',
      },
      {
        value: 'memberGroupId',
        type: 'String',
      },
      {
        value: 'memberId',
        type: 'String',
      },
      {
        value: 'amount',
        type: 'Number',
      },
      {
        value: 'closingPrice',
        type: 'Number',
      },
      {
        value: 'wonPrice',
        type: 'Number',
      },
      {
        value: 'mallType',
        type: 'String',
      },
      {
        value: 'mallOrderNum',
        type: 'String',
      },
      {
        value: 'memberName',
        type: 'String',
      },
      {
        value: 'productName',
        type: 'String',
      },
      {
        value: 'orderDate',
        type: 'String',
      },
      {
        value: 'etc',
        type: 'String',
      },
      {
        value: 'memo',
        type: 'String',
      },
      {
        value: 'errorType',
        type: 'String',
      },
      {
        value: 'errorMessage',
        type: 'String',
      },
    ],
    code: ['3000', '2011', '1014'],
  },
  //hkAccount
  {
    status: true,
    method: 'GET',
    url: API_URL + 'hkAccount/info',
    path: 'hkAccount/info',
    summary: '한경 계좌 잔액 조회',
    comments: ['info.currentBalance 값을 통해 확인 가능', 'currentBalance는 PEB 단위로 환산된 KLAY'],
    params: [
      {
        value: 'accountId',
        required: true,
        type: 'String',
      },
    ],
    body: null,
    response: [
      {
        value: 'result',
        type: 'Boolean',
      },
      {
        value: 'info',
        type: 'Object',
      },
    ],
    code: ['3000', '2011', '1023'],
  },
  //klaytn/ticker
  {
    status: true,
    method: 'GET',
    url: API_URL + 'klaytn/ticker',
    path: 'klaytn/ticker',
    summary: '클레이튼 원화 시세 조회',
    comments: ['클레이튼 원화 시세 조회 - 기준 bithumb(빗썸)', 'data값은 bithumb api의 response.data'],
    params: [],
    body: null,
    response: [
      {
        value: 'result',
        type: 'Boolean',
      },
      {
        value: 'closing_price',
        type: 'String',
      },
      {
        value: 'data',
        type: 'Object',
      },
    ],
    code: [''],
  },
];
