import React, { useEffect, useState } from 'react';
import Utils from '../utils';
import _ from 'lodash';

var offset = 80; // sticky nav height

export const Navigation = () => {
  const API_LIST = Utils.getApiList();
  const [activeIndex, setActiveIndex] = useState(-1);

  const onClickNav = (api) => {
    let targetDom = document.getElementById('api-document-' + api.url);
    window.scroll({ top: targetDom.offsetTop - offset, left: 0, behavior: 'smooth' });
  };

  const checkActiveItem = _.throttle((e) => {
    let windowScrollY = window.scrollY;
    console.log('windowScrollY', windowScrollY);

    for (let i in API_LIST) {
      let target = document.getElementById('api-document-' + API_LIST[i].url);
      let targetRect = target.getBoundingClientRect();
      let targetAbsY = windowScrollY + targetRect.y;
      if (targetRect.y > 0 && targetAbsY + targetRect.height > windowScrollY) {
        setActiveIndex(Number(i));
        break;
      }
    }
  }, 200);

  useEffect(() => {
    window.document.addEventListener('scroll', checkActiveItem);
  }, []);

  return (
    <article className="navigation">
      <ul>
        {API_LIST.map((api, index) => {
          let liClassName = 'nav-item';
          if (index === activeIndex) {
            liClassName += ' active';
          }
          return (
            <li
              className={liClassName}
              onClick={() => {
                onClickNav(api);
              }}
            >
              <b className={api.method}>{api.method}</b>
              <span>{api.path}</span>
            </li>
          );
        })}
      </ul>
    </article>
  );
};
