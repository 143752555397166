import { API_LIST, PARAMETERS_DESCRIPTION, RESPONSE_DESCRIPTION, RESULT_LIST } from '../data';

const getApiList = () => {
  return API_LIST;
};

const getParamDesc = (value) => {
  for (let i in PARAMETERS_DESCRIPTION) {
    if (PARAMETERS_DESCRIPTION[i].value === value) {
      return PARAMETERS_DESCRIPTION[i].text;
    }
  }
  return '';
};

const getResDesc = (value) => {
  for (let i in RESPONSE_DESCRIPTION) {
    if (RESPONSE_DESCRIPTION[i].value === value) {
      return RESPONSE_DESCRIPTION[i].text;
    }
  }
  return '';
};

const getCodeMessage = (code) => {
  for (let i in RESULT_LIST) {
    if (RESULT_LIST[i].id === Number(code)) {
      return RESULT_LIST[i].message;
    }
  }
  return '';
};

const Utils = {
  getApiList: getApiList,
  getParamDesc: getParamDesc,
  getResDesc: getResDesc,
  getCodeMessage: getCodeMessage,
};

export default Utils;
