import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import { MainLayout, MobileLayout } from './layouts';

import { Home, ExamplePage } from './pages';

const App = () => {
  return (
    <Router>
      <Switch>
        <MainLayout exact path="/" Component={Home}></MainLayout>
        <MobileLayout path="/example" Component={ExamplePage}></MobileLayout>
      </Switch>
    </Router>
  );
};

export default App;
