import React, { useState, useEffect } from 'react';
import { Route, Link } from 'react-router-dom';
import { Navigation } from '../components';

export const MainLayout = (props) => {
  let { Component, headerText, ...rest } = props;

  return (
    <div className="main-layout" id="main-layout">
      <div className="main-header">
        <div className="main-header-wrapper">
          <div className="logo">
            <Link to="/">
              <b>Shift API Guide</b>
            </Link>
          </div>
        </div>
      </div>
      <div className="header__placeholder"></div>
      <div className="content-wrapper">
        <div className="nav__container">
          <Navigation />
        </div>
        <div className="content__container" id="content__container">
          <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
        </div>
      </div>
    </div>
  );
};
